import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/chatp/Projects/farming-season/doc/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "roadmap"
    }}>{`Roadmap`}</h1>
    <p>{`We do have a rough plan for Farming Season. However, it might be changed later. As mentioned many times that the game focuses on educational purpose and investing is not recommended at all, we do not want to limit ourselves just to follow the plan but to adapt the game based on the feedback and try to make Farming Season educational and enjoyable as much as possible`}</p>
    <h3 {...{
      "id": "first-phase-by-q1-2022"
    }}>{`First Phase (By Q1, 2022)`}</h3>
    <p>{`✅ Launch NFT Market`}</p>
    <p>{`✅ Launch Farming Season`}</p>
    <p>{`✅ Character Burning System`}</p>
    <p>{`✅ Facilitating Machines`}</p>
    <p>{`✅ Fox Invading System`}</p>
    <p>{`✅ Animals Petting`}</p>
    <p>{`✅ Phra-Phum Shrine Interaction`}</p>
    <p>{`✅ Thai Language Support`}</p>
    <h3 {...{
      "id": "second-phase-by-q2-2022"
    }}>{`Second Phase (By Q2, 2022)`}</h3>
    <p>{`✅ Fishing`}</p>
    <p>{`⬜️ Processing Machines`}</p>
    <p>{`⬜️ Fortune-telling Cow`}</p>
    <p>{`⬜️ New Character Skills`}</p>
    <p>{`⬜️ Maid`}</p>
    <h3 {...{
      "id": "third-phase-by-q3-2022"
    }}>{`Third Phase (By Q3, 2022)`}</h3>
    <p>{`⬜️ Country Life Town`}</p>
    <p>{`⬜️ Buffalo Racing`}</p>
    <p>{`⬜️ Neighbor`}</p>
    <h3 {...{
      "id": "forth-phase-by-q4-2022"
    }}>{`Forth Phase (By Q4, 2022)`}</h3>
    <p>{`⬜️ See how the game did and improve`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      